import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import PaidImg from '../../assets/paid-channel-img.png'
import FreeImg from '../../assets/free-channel-img.png'
import PlayIcon from '../../assets/playlist-play-icon.svg'
import PauseIcon from '../../assets/playlist-pause-icon.svg'
import LiveIcon from '../../assets/live-icon-channel.png'
import LockIcon from '../../assets/lock-icon.svg'
import {
  CONTENT_FILTERING,
  CONTENT_WATCH_URL,
  IPTV_CHANNEL_SCHEDULE,
  GET_ACTIVE_SUBSCRIPTIONS,
  IPTV_CHANNEL_FILTER,
  GET_REVIEWS_API,
} from '../../utils/apiUrls'
import useAxios from 'axios-hooks'
import { convertToLocalTime, getImage } from '../../utils/helper'
import { AppContext } from '../../context/app'
import { intersectionWith, isEmpty, isEqual } from 'lodash'
import CustomImage from '../../components/customImage/customImage'
import ReviewsListing from '../../components/reviewRating'
import CommentListing from '../../components/comments'
import { SubscriptionContext } from '../../context/subscription'
import ChannelPlayer from '../player/channelPlayer'
import ChannelDateSlider from './channelDateSlider'
import './channel.css'
import moment from 'moment'
import ChannelAbout from './channelAbout'
import ChannelBottom from './channelBottom'
import { withTheme } from 'theming'
import { useInterval } from '../../hooks/useInterval'
import ChannelRelated from './channelRelated'
import { useTranslation } from 'react-i18next'

// let currentIndexGlobal = 0
const Channel = ({ theme }) => {
  const channelId = useParams().channelId
  const { t } = useTranslation()
  const { isLogin, setLoader, setOpenLoginModal, setModalType, accountObj } = useContext(AppContext)
  const { activeSubscription, setOpenSubscriptionModal, setSubscriptions, setRedirectionUrl } =
    useContext(SubscriptionContext)
  const [selectedDate, setSelectedDate] = useState(moment().format())
  const [detailObj, setDetailObj] = useState({})
  const [isChannelSubscribed, setIsChannelSubscribed] = useState(true)
  const [currentPlayingContent, setCurrentPlayingContent] = useState({})
  const [currentPlayingEndTime, setCurrentPlayingEndTime] = useState(null)
  const [nextStartTime, setNextStartTime] = useState(null)
  const [playerQueue, setPlayerQueue] = useState([])
  const [videoSource, setVideoSource] = useState(null)
  const [scheduleData, setScheduleData] = useState([])

  const [{ data: reviewList, loading: reviewListLoading }] = useAxios({
    url: GET_REVIEWS_API + '/channel/' + channelId,
    method: 'get',
  })

  const [{ data: channelDetails, loading: channelDetailsLoading }, channelDetailsCall] = useAxios(
    {
      url: IPTV_CHANNEL_FILTER,
      method: 'post',
    },
    {
      manual: true,
    },
  )

  const [{ data: channelSchedule, loading: channelScheduleLoading }, channelScheduleCall] =
    useAxios(
      {
        url: IPTV_CHANNEL_SCHEDULE,
        method: 'get',
      },
      {
        manual: true,
      },
    )

  const [{ loading: scheduleContentLoading }, scheduleContentCall] = useAxios(
    {
      url: CONTENT_FILTERING,
      method: 'post',
    },
    { manual: true },
  )

  const [{ data: contentWatchUrl, loading: contentWatchUrlLoading }, getContentWatchUrl] = useAxios(
    {
      url: `${CONTENT_WATCH_URL}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ loading: activeSubLoading }, activeSubCall] = useAxios(
    {
      url: GET_ACTIVE_SUBSCRIPTIONS,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(
      channelDetailsLoading ||
        channelScheduleLoading ||
        reviewListLoading ||
        scheduleContentLoading ||
        contentWatchUrlLoading ||
        activeSubLoading,
    )
  }, [
    channelDetailsLoading,
    channelScheduleLoading,
    reviewListLoading,
    scheduleContentLoading,
    contentWatchUrlLoading,
    activeSubLoading,
  ])

  useEffect(() => {
    if (contentWatchUrl && contentWatchUrl.success) {
      setVideoSource(contentWatchUrl?.data?.signed_url)
    }
  }, [contentWatchUrl])

  useEffect(() => {
    setVideoSource(null)
    setCurrentPlayingContent({})
    channelDetailsCall({
      data: {
        filters: { channel_ids: [channelId] },
        page: '1',
      },
    })

    channelScheduleCall({
      params: {
        channel_id: channelId,
        start_time: moment().subtract(7, 'd').startOf('day').toISOString(),
        end_time: moment().endOf('day').toISOString(),
      },
    })
  }, [channelId])

  useEffect(() => {
    if (channelDetails && channelDetails.success) {
      setDetailObj(channelDetails.data[0])
    }
  }, [channelDetails])

  useEffect(async () => {
    if (channelSchedule && channelSchedule.success) {
      let tempSchduleArr = []
      let contentIDs = channelSchedule?.data.map((info) => info?.content_id)
      let contents = await scheduleContentCall({
        data: {
          filters: {
            content_ids: contentIDs,
          },
          page: 1,
          page_size: contentIDs.length,
        },
      })

      for (let i = 0; i < channelSchedule?.data.length; i++) {
        const scheduledContent = channelSchedule?.data[i]
        let contentObj = contents.data?.data.find(
          (content) => content.content_id === scheduledContent?.content_id,
        )

        if (contentObj) {
          tempSchduleArr.push({
            ...scheduledContent,
            contentDetail: contentObj,
          })
        }
      }
      setScheduleData([...tempSchduleArr])
    }
  }, [channelSchedule, isLogin])

  useEffect(async () => {
    if (!isEmpty(detailObj) && !isEmpty(scheduleData)) {
      if (await checkRestriction()) {
        let todaysContents = scheduleData.filter((ele) => {
          return (
            moment(new Date(ele.start_time + 'Z')).format('DD-MM-YYYY') ===
              moment().format('DD-MM-YYYY') ||
            moment(new Date(ele.end_time + 'Z')).format('DD-MM-YYYY') ===
              moment().format('DD-MM-YYYY')
          )
        })

        let tempPlayerQueue = []

        for (let i = 0; i < todaysContents.length; i++) {
          const content = todaysContents[i]
          const startTime = moment(convertToLocalTime(content.start_time), 'HH:mm')
          const endTime = moment(convertToLocalTime(content.end_time), 'HH:mm')
          const time = moment(new Date(), 'HH:mm')

          if (time.isSameOrAfter(startTime) && time.isSameOrBefore(endTime)) {
            setCurrentPlayingContent(content.contentDetail)
            setCurrentPlayingEndTime(convertToLocalTime(content.end_time))
            continue
          }

          if (time.isBefore(startTime)) {
            tempPlayerQueue.push(content)
            continue
          }
        }

        setPlayerQueue([
          ...tempPlayerQueue.sort((first, second) => first.start_time - second.start_time),
        ])
      }
    }
  }, [detailObj, scheduleData, isLogin])

  useInterval(async () => {
    if (currentPlayingEndTime) {
      const time = moment(new Date(), 'HH:mm').format('HH:mm')

      if (time === currentPlayingEndTime) {
        setVideoSource(null)
        setCurrentPlayingContent({})
        setCurrentPlayingEndTime(null)
      }
    }
  }, 1000)

  useInterval(async () => {
    if (nextStartTime) {
      const time = moment(new Date(), 'HH:mm').format('HH:mm')
      if (time === nextStartTime) {
        setCurrentPlayingContent(playerQueue[0].contentDetail)
        setCurrentPlayingEndTime(convertToLocalTime(playerQueue[0].end_time))
        setNextStartTime(
          playerQueue.length > 2 ? convertToLocalTime(playerQueue[1].start_time) : null,
        )
        let tempQueue = playerQueue.filter((e, idx) => idx !== 0)
        setPlayerQueue([...tempQueue])
      }
    }
  }, 1000)

  useEffect(async () => {
    if (!isEmpty(playerQueue)) {
      setNextStartTime(convertToLocalTime(playerQueue[0].start_time))
    }
  }, [playerQueue])

  useEffect(async () => {
    if (!isEmpty(currentPlayingContent)) {
      if (currentPlayingContent.is_live && !isEmpty(currentPlayingContent.stream)) {
        setVideoSource(currentPlayingContent.stream.link)
        return
      }
      getContentWatchUrl({ url: `${CONTENT_WATCH_URL}${currentPlayingContent.content_id}` })
    }
  }, [currentPlayingContent])

  const checkPlanExist = (contentActiveSubscription, userSubscription) => {
    return !isEmpty(intersectionWith(contentActiveSubscription, userSubscription, isEqual))
  }

  const emptySubcheck = async (channelSub) => {
    const subRes = await activeSubCall()
    const activeSub = subRes.data.data
      .filter((item) => item.status)
      .map((item) => item.subscription)
    return !isEmpty(intersectionWith(channelSub, activeSub, isEqual))
  }

  const checkRestriction = async (openModal = false) => {
    if (!detailObj.is_restricted) {
      return true
    } else {
      if (!isLogin) {
        if (openModal) {
          setModalType('login')
          setOpenLoginModal(true)
        }
        setIsChannelSubscribed(false)
        return false
      } else {
        let channelSub = [
          ...detailObj?.active_subscription.map((item) => item._id),
          ...detailObj?.active_ppv.map((item) => item._id),
        ]
        const planExist = isEmpty(activeSubscription)
          ? await emptySubcheck(channelSub)
          : checkPlanExist(channelSub, activeSubscription)

        if (!planExist) {
          setIsChannelSubscribed(false)
          if (openModal) {
            setRedirectionUrl(window.location.pathname)
            setSubscriptions(channelSub)
            setOpenSubscriptionModal(true)
          }
        } else {
          return true
        }
        return false
      }
    }
  }

  const menuComponents = [
    {
      title: t('about'),
      child: <ChannelAbout description={detailObj?.description} category={detailObj?.category} />,
    },
    {
      title: t('related_channels'),
      child: (
        <ChannelRelated
          channelId={channelId}
          onChannelClick={() => {
            setVideoSource(null)
            setCurrentPlayingContent({})
            setNextStartTime(null)
            setCurrentPlayingEndTime(null)
          }}
        />
      ),
    },
    {
      title: t('reviews'),
      child: (
        <ReviewsListing
          type={'channel'}
          id={channelId}
          title={detailObj?.title}
          isRefreshed={channelDetailsLoading}
        />
      ),
    },
    {
      title: t('comments'),
      child: <CommentListing type={'channel'} id={channelId} theme={theme} />,
    },
  ]

  const getResumeTimestamp = () => {
    const currentScheduledContent = scheduleData.filter(
      (info) =>
        info.content_id === currentPlayingContent.content_id &&
        moment(new Date(info?.end_time + 'Z')).format('DD-MM-YYYY HH:mm') ===
          moment(moment(new Date()).format('MM-DD-YYYY') + ' ' + currentPlayingEndTime).format(
            'DD-MM-YYYY HH:mm',
          ),
    )

    if (currentScheduledContent.length === 1) {
      const content = currentScheduledContent[0]

      let duration = moment.duration(
        moment(new Date(), 'HH:mm').diff(moment(convertToLocalTime(content.start_time), 'HH:mm')),
      )
      return duration.asSeconds()
    }

    return 0
  }

  let filteredArr = scheduleData.filter(
    (ele) =>
      moment(new Date(ele.start_time + 'Z')).format('DD-MM-YYYY') ===
        moment(selectedDate).format('DD-MM-YYYY') ||
      moment(new Date(ele.end_time + 'Z')).format('DD-MM-YYYY') ===
        moment(selectedDate).format('DD-MM-YYYY'),
  )

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full lg:h-[91.25vh] relative">
        <div className="w-full lg:h-[91.25vh] flex justify-center items-center z-10 lg:bg-transparent bg-[#1b242f]">
          <div
            className=" flex flex-col z-10 lg:w-[88.5%] w-full  lg:bg-transparent bg-[#1b242f] justify-between"
            style={{
              aspectRatio: 2756 / 1000,
              outline: '2px solid transparent',
            }}
          >
            <div
              style={{
                backgroundColor: '#000000',
                color: '#fff',
              }}
              className="w-full z-10 flex flex-col justify-between lg:rounded-xl overflow-hidden aspect-[390/167] lg:aspect-[1699/191]"
            >
              <div className="w-full flex justify-between lg:h-full h-[62%]">
                <div
                  className=" relative pt-[4.62vw] pl-[4vw] 2xl:pt-[1.8vw] 2xl:pl-[1.5vw] xl:pt-[1.2vw]  lg:pl-[1vw] w-[35%] lg:w-[28%] h-full"
                  // style={{ width: '28%' }}
                >
                  {detailObj?.is_restricted ? (
                    <img
                      src={PaidImg}
                      className="absolute -right-16 lg:-right-20 w-[15vw] lg:w-[8.125vw]"
                      alt="PaidImg"
                    />
                  ) : (
                    <img
                      src={FreeImg}
                      className="absolute -right-16 lg:-right-20 w-[15vw] lg:w-[7.5vw]"
                      alt="FreeImg"
                    />
                  )}
                  <p
                    style={{ marginBlockEnd: '0' }}
                    className="font-bold 2xl:text-3xl lg:text-2xl text-xl line-clamp-2 lg:max-w-[80%]"
                  >
                    {detailObj?.name}
                  </p>
                  <div style={{ marginTop: '1vw' }} className="lg:flex hidden">
                    {!isEmpty(detailObj) &&
                      [...detailObj?.category]
                        .filter((ele, idx) => idx < 4)
                        .map((item, index) => (
                          <p
                            style={{
                              marginBlockEnd: '0',
                              background: '#636363',
                              padding: '2px 5px 2px 5px',
                              marginLeft: index ? '0.625vw' : '',
                            }}
                            className="text-sm rounded line-clamp-1"
                            key={index}
                          >
                            {item.name}
                          </p>
                        ))}
                    {/* {detailObj?.category.map((info) => info.name).toString()} */}
                  </div>
                  <div
                    style={{ marginTop: '1vw', color: '#aeaeae' }}
                    className="lg:flex hidden items-center"
                  >
                    {!isEmpty(reviewList?.data) && (
                      <div
                        style={{ border: '0.5px solid #989898', borderRadius: '3px' }}
                        className="w-fit "
                      >
                        <span
                          className="px-2 font-semibold text-xs"
                          style={{ borderRight: '0.5px solid #989898', textAlign: 'center' }}
                        >
                          {(
                            reviewList?.data
                              .map((review) => review.rating)
                              ?.reduce((a, b) => a + b) /
                            reviewList?.data.map((review) => review.rating).length
                          ).toFixed(1)}
                          ⭐
                        </span>
                        <span className="px-2 text-xs">
                          {reviewList?.data.map((review) => review.rating).length +
                            ' ' +
                            t('reviews')}
                        </span>
                      </div>
                    )}

                    {/* <span className="px-2 text-xs" style={{ marginLeft: '2.55vw' }}>
                      1.9K Viewers
                    </span> */}
                  </div>
                </div>
                <div style={{ zIndex: -1 }} className="w-[65%] lg:w-[72%] h-full relative flex">
                  <div
                    style={{
                      //   width: '72%',
                      backgroundImage: `linear-gradient(to right, #000, rgba(16, 22, 28, 0) 40%)`,
                      backgroundSize: 'cover',
                      zIndex: 10,
                    }}
                    className="w-full h-full"
                  />
                  <CustomImage
                    imageUrl={getImage(
                      accountObj?.cf_domain_name,
                      detailObj?.banner?.website?.s3_key,
                    )}
                    imgStyle={{ zIndex: 0 }}
                    imgClass="absolute w-full h-full"
                    loaderClass="w-full h-full"
                    imgAlt="banner"
                  />
                </div>
              </div>
              <div
                style={{ background: '#1b242f' }}
                className=" flex flex-col justify-between py-1 px-4 items-start lg:hidden h-[38%]"
              >
                <div className="flex">
                  {!isEmpty(detailObj) &&
                    [...detailObj?.category]
                      .filter((ele, idx) => idx < 4)
                      .map((item, index) => (
                        <p
                          style={{
                            marginBlockEnd: '0',
                            background: '#636363',
                            padding: '2px 5px 2px 5px',
                            marginLeft: index ? '2.8vw' : '',
                          }}
                          className="text-xs rounded"
                          key={index}
                        >
                          {item.name}
                        </p>
                      ))}
                </div>
                <div className="flex items-center" style={{ color: '#aeaeae' }}>
                  {!isEmpty(reviewList?.data) && (
                    <div
                      style={{ border: '0.5px solid #989898', borderRadius: '3px' }}
                      className="w-fit"
                    >
                      <span
                        className="px-2 font-semibold text-xs"
                        style={{ borderRight: '0.5px solid #989898', textAlign: 'center' }}
                      >
                        {(
                          reviewList?.data.map((review) => review.rating)?.reduce((a, b) => a + b) /
                          reviewList?.data.map((review) => review.rating).length
                        ).toFixed(1)}
                        ⭐
                      </span>
                      <span className="px-2 text-xs">
                        {reviewList?.data.map((review) => review.rating).length +
                          ' ' +
                          t('reviews')}
                      </span>
                    </div>
                  )}
                  {/* <span className="px-2 text-xs" style={{ marginLeft: '2.55vw' }}>
                    1.9K Viewers
                  </span> */}
                </div>
              </div>
            </div>
            <div
              style={{
                aspectRatio: '1699/592',
                outline: '2px solid transparent',
                color: '#fff',
              }}
              className="w-full z-10 flex lg:flex-row flex-col justify-between lg:mt-4"
            >
              <div
                style={{
                  backgroundColor: '#000000',
                  outline: '2px solid transparent',
                  // width: '62%',
                  // aspectRatio: '1052/592',
                  overflow: 'hidden',
                }}
                className="z-10 flex justify-center items-center  lg:rounded-xl w-full lg:w-[62%] aspect-[1052/592] lg:aspect-auto"
              >
                {true && (
                  <ChannelPlayer
                    contentInfo={currentPlayingContent}
                    contentWatchUrl={videoSource}
                    resumeTime={getResumeTimestamp()}
                    watermarkLogo={detailObj?.logo?.website?.s3_key}
                    // handlePlayingSequence={handlePlayingSequence}
                  />
                )}
                {/* {videoSource && (
                  <ChannelPlayer
                    contentInfo={currentPlayingContent}
                    contentWatchUrl={videoSource}
                    resumeTime={getResumeTimestamp()}
                    watermarkLogo={detailObj?.logo?.website?.s3_key}
                    // handlePlayingSequence={handlePlayingSequence}
                  />
                )} */}
                {/* {!videoSource ? (
                  !isChannelSubscribed ? (
                    <div className=" w-[70%] md:w-fit">
                      <div className="flex px-5 md:px-0">
                        <img
                          src={LockIcon}
                          style={{
                            width: '18.5px',
                            height: '24.6px',
                          }}
                          alt="LockIcon"
                        />
                        <p
                          style={{ color: '#cecece' }}
                          className="md:ml-2 text-xs md:text-base xl:text-lg text-center "
                        >
                          {t('this_channel_not_included')}
                        </p>
                      </div>
                      <button
                        style={{ background: '#09e9e9', color: '#000' }}
                        className="w-full text-xs md:text-base xl:text-lg rounded-md xl:rounded-lg mt-4 xl:mt-6 py-1"
                        onClick={() => checkRestriction(true)}
                      >
                        {t('upgrade_membership_to_watch')}
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <CustomImage
                        imageUrl={getImage(
                          accountObj?.cf_domain_name,
                          detailObj?.logo?.website?.s3_key,
                        )}
                        imgAlt="channelimg"
                        imgClass={`   h-6 w-9 md:h-12 md:w-20 `}
                        loaderClass={`   h-6 w-9 md:h-12 md:w-20 `}
                      />
                      <p
                        style={{ color: '#cecece' }}
                        className="md:ml-2 text-xs md:text-base xl:text-lg text-center "
                      >
                        {t('no_programs_shown_at_moment')}
                      </p>
                    </div>
                  )
                ) : null} */}
              </div>
              <div
                style={{
                  outline: '2px solid transparent',
                  // width: '35%',
                  // aspectRatio: '593/592',
                }}
                className="flex flex-col overflow-hidden 2xl:h-auto lg:rounded-xl w-full lg:w-[35%] aspect-[593/592] lg:aspect-auto"
              >
                <div
                  style={{
                    height: '49px',
                    maxWidth: '593px',
                    // backgroundColor: '#000000',
                  }}
                  className=" lg:pt-3 pt-1 lg:border-0 lg:bg-black"
                >
                  <ChannelDateSlider
                    selectedDate={selectedDate}
                    onChange={(value) => setSelectedDate(value)}
                  />
                </div>
                <div
                  style={{
                    padding: '3% 1.5%',
                    maxHeight: `535px`,
                    overflowX: 'hidden',
                  }}
                  className="lg:mt-1 h-full scrollbar-class lg:bg-black"
                >
                  {!isEmpty(scheduleData) && !isEmpty(filteredArr) ? (
                    filteredArr.map((info, index) => {
                      const currentLiveContent =
                        currentPlayingContent?.content_id === info?.content_id &&
                        Date.now() + convertToLocalTime(info.end_time) ===
                          Date.now() + currentPlayingEndTime

                      return (
                        <div
                          key={index}
                          className={`py-2 flex w-full`}
                          style={{
                            outline: '2px solid transparent',
                            paddingLeft: '2.5%',
                            paddingRight: '2.5%',
                            borderBottom: '0.5px solid #ffffff80',
                          }}
                        >
                          <div
                            style={{ minWidth: '150px', height: '85px' }}
                            className="relative flex justify-center items-center "
                            // onClick={() => handleChangeVideo(index)}
                          >
                            <CustomImage
                              imageUrl={getImage(
                                accountObj?.cf_domain_name,
                                info?.contentDetail?.thumbnail,
                              )}
                              imgClass="w-full h-full z-0 absolute rounded-lg"
                              loaderClass={'w-full h-full z-0 absolute rounded-lg'}
                              imgAlt={'play'}
                            />
                            {videoSource &&
                            currentLiveContent &&
                            moment(new Date(info.start_time + 'Z')).format('DD-MM-YYYY') ===
                              moment(new Date()).format('DD-MM-YYYY') ? (
                              <div
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  background: '#ffffff95',
                                }}
                                className="absolute z-10 flex justify-center items-center rounded-full"
                              >
                                <img src={PauseIcon} alt="PauseIcon" />
                              </div>
                            ) : (
                              <img
                                style={{ width: '20px', height: '20px' }}
                                className="absolute z-10"
                                src={PlayIcon}
                                alt="PlayIcon"
                              />
                            )}
                          </div>
                          <div style={{ marginLeft: '5%' }}>
                            <p className="font-semibold 2xl:text-xl xl:text-lg lg:text-base line-clamp-1">
                              {/* {info?.contentDetail?.title} */}
                              {index + 1 !== filteredArr.length
                                ? info?.contentDetail?.title
                                : 'Nairobi City Thunder v Kriol Star | Africa Champions Clubs ROAD TO B.A.L. 2025 | FINAL'}
                            </p>
                            <div className="flex items-center xl:mt-3 mt-1">
                              <p className="xl:text-sm text-xs ">
                                {/* {`${convertToLocalTime(info.start_time)} - ${convertToLocalTime(
                                  info.end_time,
                                )}`} */}
                                {index + 1 !== filteredArr.length
                                  ? `${convertToLocalTime(info.start_time)} - ${convertToLocalTime(
                                      info.end_time,
                                    )}`
                                  : `${convertToLocalTime('2024-12-02T17:30:00')}`}
                              </p>
                              {/* {moment(new Date(info.start_time + 'Z')).format('DD-MM-YYYY') ===
                                moment(new Date()).format('DD-MM-YYYY') &&
                                videoSource &&
                                currentLiveContent && (
                                  <img
                                    src={LiveIcon}
                                    className=" h-5 mx-2 lg:mx-4"
                                    alt="LiveIcon"
                                  />
                                )} */}
                              {index + 1 === filteredArr.length && (
                                <img src={LiveIcon} className=" h-5 mx-2 lg:mx-4" alt="LiveIcon" />
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <p className="w-full text-center">{t('no_program_scheduled')}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChannelBottom options={menuComponents} />
    </div>
  )
}

export default withTheme(Channel)
